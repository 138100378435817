<template>
  <v-card
    class="mx-auto"
    elevation=4
    outlined
    style="border-radius: 0.5rem;"
    max-width="500"
  >
    <CreateSite
      ref="create_dialog"
      :access="access"
      :oauth="{
        type: 'Shopify',
        url: `${$route.query.shop.replace('.myshopify.com','')}`,
        api_user: '-',
        api_key: '-',
        link_shopify_oauth: link_shopify_oauth
      }"
    ></CreateSite>
    <v-card-title class="justify-center">
      Shopify OAuth
    </v-card-title>
    <div class="text-center" style="padding-left: 10px; padding-right: 10px;">
      Link to Shopify Website
      <v-select
        :items="shopify_sites"
        v-model="selected_website"
        item-text="name"
        item-value="website_id"
        label="Shopify Site"
        outlined
        style="margin-bottom: 0px !important; height: 70px !important;"
      ></v-select>
      <v-btn
        class="mx-2 rounded-lg"
        elevation=4
        :disabled="selected_website === ''"
        style="margin-bottom: 10px;"
        @click="link_shopify_oauth(selected_website)"
      >
        Confirm
      </v-btn>
    </div>
      <v-row align="center" style="margin-left: 10px; margin-right: 10px">
        <v-divider></v-divider><p style="padding-left: 10px; padding-right: 10px; margin-top: 10px; font-size: 30px; font-weight: 200;">OR</p><v-divider></v-divider>
      </v-row>
    <v-card-text>
      <v-tooltip bottom :disabled="!siteLimitMet">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn elevation=4 class="button-style rounded-lg" @click="$refs.create_dialog.show()" :disabled="siteLimitMet">Create Website</v-btn>
          </div>
        </template>
        <span>You have reached your website limit.</span>
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<script>
import { websitesCore, websitesLogic } from "@/mixins/websitesMixin.js";
import CreateSite from "@/components/sites/CreateSite.vue"
export default {
  mixins: [websitesCore, websitesLogic],
  data() {
      return {
        access: this.$store.getters.getAccessLevel['websites'],
        selected_website: ""
      }
  }, 
  computed: {
    siteLimitMet() {
      return this.$store.getters.getOrganizationSettings.website_limit <= this.$store.getters.getOrganizationState.website_count;
    },
    shopify_sites() {
      return this.websites.filter(website => {
        return website.type === "Shopify";
      })
    }
  },
  components: {
    CreateSite
  },
  methods: {
    async link_shopify_oauth(website_id) {
      await this.addOauthToWebsite({"website_id": website_id, "code": this.$route.query.code});
      // If success
      this.$router.push(`/sites/${website_id}`);
    }
  },
}
</script>