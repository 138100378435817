var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",staticStyle:{"border-radius":"0.5rem"},attrs:{"elevation":"4","outlined":"","max-width":"500"}},[_c('CreateSite',{ref:"create_dialog",attrs:{"access":_vm.access,"oauth":{
      type: 'Shopify',
      url: `${_vm.$route.query.shop.replace('.myshopify.com','')}`,
      api_user: '-',
      api_key: '-',
      link_shopify_oauth: _vm.link_shopify_oauth
    }}}),_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Shopify OAuth ")]),_c('div',{staticClass:"text-center",staticStyle:{"padding-left":"10px","padding-right":"10px"}},[_vm._v(" Link to Shopify Website "),_c('v-select',{staticStyle:{"margin-bottom":"0px !important","height":"70px !important"},attrs:{"items":_vm.shopify_sites,"item-text":"name","item-value":"website_id","label":"Shopify Site","outlined":""},model:{value:(_vm.selected_website),callback:function ($$v) {_vm.selected_website=$$v},expression:"selected_website"}}),_c('v-btn',{staticClass:"mx-2 rounded-lg",staticStyle:{"margin-bottom":"10px"},attrs:{"elevation":"4","disabled":_vm.selected_website === ''},on:{"click":function($event){return _vm.link_shopify_oauth(_vm.selected_website)}}},[_vm._v(" Confirm ")])],1),_c('v-row',{staticStyle:{"margin-left":"10px","margin-right":"10px"},attrs:{"align":"center"}},[_c('v-divider'),_c('p',{staticStyle:{"padding-left":"10px","padding-right":"10px","margin-top":"10px","font-size":"30px","font-weight":"200"}},[_vm._v("OR")]),_c('v-divider')],1),_c('v-card-text',[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.siteLimitMet},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"button-style rounded-lg",attrs:{"elevation":"4","disabled":_vm.siteLimitMet},on:{"click":function($event){return _vm.$refs.create_dialog.show()}}},[_vm._v("Create Website")])],1)]}}])},[_c('span',[_vm._v("You have reached your website limit.")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }