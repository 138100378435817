<template>
  <component :is="flat ? 'div' : 'v-dialog'" v-model="show" :max-width="max_width" @click:outside="show = false" @keydown.esc="show = false">
    <v-card class="rounded-lg">
      <v-card-title>
        {{ title }}
        <v-spacer/>
        <slot name="actions" v-bind:options="options">
          <slot name="cancel-action">
            <v-btn class="rounded-lg mr-2" color="grey darken-1" text @click="options.cancel()">Cancel</v-btn>
          </slot>
          <v-btn v-if="step < steps" class="rounded-lg" outlined :color="color" @click="$refs[`step_${step}`][0].validate() ? step++:''">Continue</v-btn>
          <slot name="confirm-action" v-bind:options="options" v-else>
            <v-btn class="rounded-lg" outlined :color="color" @click="options.confirm(options.data)">Confirm</v-btn>
          </slot>
        </slot>
      </v-card-title>
      <v-divider/>

      <v-stepper v-model="step" non-linear flat>
        <v-stepper-header style="box-shadow: none; height: auto;">
          <template v-for="current_step in steps">
            <v-stepper-step :key="current_step" :step="current_step" :rules="[() => steps_valid[current_step]]" editable @click="$refs[`step_${step}`][0].validate()">
              <slot :name="`step_${current_step}_header`">
                <div v-text="`<template #step_${current_step}_header>`"></div>
              </slot>
              <small>
                <slot :name="`step_${current_step}_subheader`"></slot>
              </small>
            </v-stepper-step>
            <v-divider :key="`${current_step}_divider`" v-if="current_step < steps" />
          </template>
        </v-stepper-header>
        <v-divider/>

        <v-stepper-items>
          <v-stepper-content v-for="current_step in steps" :key="current_step" :step="current_step" class="pa-2">
            <v-form v-model="steps_valid[current_step]" :ref="`step_${current_step}`" lazy-validation>
              <v-container>
                <slot :name="`step_${current_step}_content`" v-bind:options="options">
                  <div v-text="`Use <template #step_${current_step}_content></template> to change this step. Use { options } to access the step data.`"></div>
                </slot>
              </v-container>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </component>
</template>

<script>
import { VDialog } from 'vuetify/lib'
export default {
  components: {VDialog},
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    color: {
      type: String,
      default: 'primary'
    },
    max_width: {
      type: String,
      default: '35%'
    },
    steps: {
      type: Number,
      default: 1
    },
    flat: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      step: 1,
      steps_valid: [],
      options: {
        data: {},
        confirm: null,
        cancel: null,
      },
    }
  },
  methods: {
    showDialog(data={}) {
      return new Promise((resolve, reject) => {
        const res = (result) => {
          // Ensure all steps are valid before resolving
          if (this.validateAllSteps()) {
            this.show = false;
            this.step = 1;
            this.options = { data: data, confirm: null, cancel: null };
            resolve(result);
          }
        }
        const rej = () => {
          // Reset validation of all steps
          this.resetAllSteps();
          this.show = false;
          this.step = 1;
          this.options = { data: data, confirm: null, cancel: null };
          reject();
        }
        this.options = { data: data, confirm: res, cancel: rej };
        this.show = true;
      })
    },
    validateAllSteps() {
      let all_steps_valid = true;
      for (var i = 1; i <= this.steps; i++) {
        if (this.$refs[`step_${i}`][0].validate() === false) {
          all_steps_valid = false;
        }
      }
      return all_steps_valid;
    },
    resetAllSteps() {
      for (var i = 1; i <= this.steps; i++) {
        this.$refs[`step_${i}`][0].resetValidation(); 
      }
    }
  }
}
</script>