<template>
  <div>
    <StepperDialog ref="create_website_dialog" :title="title" max_width="65%" :steps="2" :flat="flat">
      <template #cancel-action v-if="!showOptions"><span/></template>
      <template #confirm-action="{ options }">
        <v-menu open-on-hover bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="rounded-lg" outlined color="primary" v-bind="attrs" v-on="on" :disabled="!siteDetailsValid" @click="options.confirm(oauth ? 'oauth' : 'normal')">Save</v-btn>
          </template>

          <v-list nav v-if="showOptions">
            <v-list-item link @click="options.confirm('another')">
              <v-list-item-content>
                <v-list-item-title>Save & New</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="options.confirm('close')">
              <v-list-item-content>
                <v-list-item-title>Save & Close</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #step_1_header><span>Website Details</span></template>
      <template #step_1_content=""> 
        <v-row>
          <v-col cols=12 style="padding: 16px 12px 0px 12px">
            <v-text-field ref="name_input" label="Name" v-model="website.name" :rules="rules.name" :readonly="!access.create_access" outlined></v-text-field>
          </v-col>

          <v-col v-if="!oauth" cols=12 style="padding: 0px 12px">
            <v-select ref="type_input" label="Type" v-model="website.type" :items="website_types" :rules="rules.type" :readonly="!access.create_access" outlined></v-select>
          </v-col>

          <v-col cols=12 style="padding: 0px 12px">
            <v-text-field ref="url_input" label="URL" v-model="website.url" :placeholder="urlPlaceHolder" :rules="rules.url" :readonly="!access.create_access || !!oauth" :prefix="prefix" :suffix="suffix" outlined></v-text-field>
          </v-col>
          
          <v-col v-if="!oauth" cols=12 style="padding: 0px 12px">
            <v-text-field ref="api_user_input" :label="api_user_label" v-model="website.api_user" :rules="rules.user_name" :readonly="!access.create_access" outlined>
              <template v-slot:append v-if="website.type == 'Shopify'">
                <v-icon @click="showShopifyInstructions = true">mdi-help-circle-outline</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col v-if="!oauth" cols=12 style="padding: 0px 12px 8px 12px">
            <v-text-field ref="api_key_input" :label="api_key_label" v-model="website.api_key" :rules="rules.api_key" :readonly="!access.create_access" outlined>
              <template v-slot:append v-if="website.type == 'Shopify'">
                <v-icon @click="showShopifyInstructions = true">mdi-help-circle-outline</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </template>

      <template #step_2_header><span>Website Settings</span></template>
      <template #step_2_content="">
        <v-row v-if="website.type !== ''">
          <v-col cols=12 v-for="value, setting in website.settings" :key="setting" style="padding: 12px 24px;">
            <v-checkbox v-model="website.settings[setting]" :label="`${setting_names[setting]}`"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols=12>
            <h3>Select Site Type First</h3>
          </v-col>
        </v-row>
      </template>
    </StepperDialog>
    <!-- Dialog for Shopify API Key Instructions -->
    <v-dialog v-model="showShopifyInstructions" max-width="700" @click:outside="showShopifyInstructions = false">
      <v-card>
        <v-container grid-list-md text-xs-center>
          <v-container fluid>
            <v-row>
              <v-col>
                <h1 style="padding-bottom: 20px;">Shopify Custom App Instructions</h1>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <br/>
                <ol>
                  <li>Create a Custom App follwing the <a href="https://help.shopify.com/en/manual/apps/custom-apps#create-and-install-a-custom-app" target="blank">Shopify Instruction Here</a>.
                    <ul><li>App Name: ItemFlo</li></ul>
                  </li>
                  <li>Set Admin API Access Scopes
                    <ul>
                      <li>Product Listings: Read and Write</li>
                      <li>Products: Read and Write</li>
                      <li>Files: Read and Write</li>
                      <li>Inventory: Read and Write</li>
                      <li>Locations: Read</li>
                    </ul>
                  </li>
                  <li>Click Install App</li>
                  <li>Copy The API Key and Access Token
                    <ul>
                      <li>Paste the API Key in the relative field</li>
                      <li>Paste the API access token in the relative field</li>
                    </ul>
                  </li>
                </ol>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
var clone = require('clone');
import { websitesLogic } from "@/mixins/websitesMixin.js";
import StepperDialog from "../StepperDialog.vue"
export default {
  mixins: [websitesLogic],
  components: {StepperDialog},
  data() {
    return {
      showShopifyInstructions: false,
      website: {
        name: '',
        type: '',
        url: '',
        api_user: '',
        api_key: '',
        settings: {}
      },
      website_types: ["B2BWave", "Shopify"],
      setting_names: {
        'track_quantity': 'Track Quantity',
        'allow_backorder': 'Allow Backorders',
        'track_dimensions': 'Track Dimensions',
        'track_weight': 'Track Weight'
      },
      rules: {
        name: [v => !!v || 'Name is required'],
        type: [v => !!v || 'Type is required'],
        url: [v => !!v || 'URL is required'],
        user_name: [v => !!v || 'This field is required'],
        api_key: [v => !!v || 'This field is required'],
      }
    }
  },
  computed: {
    api_user_label() {
      let api_user_label = 'API User Name';
      switch (this.website.type) {
        case 'Shopify':
          api_user_label = 'API key';
          break;
        case 'B2BWave':
          api_user_label = 'App Code';
          break;
      }
      return api_user_label;
    },
    api_key_label() {
      let api_key_label = 'API Key';
      switch (this.website.type) {
        case 'Shopify':
          api_key_label = 'Admin API access token';
          break;
        case 'B2BWave':
          api_key_label = 'API Token';
          break;
      }
      return api_key_label;
    },
    prefix() {
      return this.website.type !== '' ? 'https://' : '';
    },
    suffix() {
      let suffix = '';
      switch (this.website.type) {
        case 'Shopify':
          suffix = '.myshopify.com';
          break;
        case 'B2BWave':
          suffix = '.b2bwave.com';
          break;
      }
      return suffix;
    },
    siteDetailsValid() {
      return this.website.name !== '' && this.website.type !== '' && this.website.url !== '' && this.website.api_user !== '' && this.website.api_key !== '';
    },
    siteLimitMet() {
      return this.$store.getters.getOrganizationSettings.website_limit <= this.$store.getters.getOrganizationState.website_count;
    },
    urlPlaceHolder() {
      return this.website.type !== '' ? 'mywebsite' : 'Select Site Type First';
    },
    websiteLogo() {
      // Return site logo to the website depending on type
      switch (this.website.type) {
        case 'B2BWave': return process.env.VUE_APP_B2BWAVE_LOGO;
        case 'Shopify': return process.env.VUE_APP_SHOPIFY_LOGO;
        default: return process.env.VUE_APP_NO_IMAGE_IMG
      }
    }
  },
  mounted() {
    if (this.flat) {
      this.show();
    }
    this.$nextTick(() => {
      // Set website details passed through from oauth.
      if (this.oauth) {
        this.website.type = this.oauth.type;
        this.website.url = this.oauth.url;
        this.website.api_user = this.oauth.api_user;
        this.website.api_key = this.oauth.api_key;
      }
    })
  },
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Create Website'
    },
    showOptions: {
      type: Boolean,
      default: true
    },
    access: Object,
    oauth: Object,
  },
  watch: {
    'website.type': function(type) {
      switch (type) {
        case 'B2BWave':
          this.website.settings = {
            'track_quantity': false,
            'allow_backorder': false,
            'track_dimensions': false,
            'track_weight': false
          }
          break;
        case 'Shopify':
          this.website.settings = {
            'track_quantity': false,
            'allow_backorder': false
          }
          break;
        default:
          break;
      }
    }
  },
  methods: {
    show() {
      this.$refs.create_website_dialog.showDialog()
      .then(create_type => {
        this.create(create_type);
      })
      .catch(() => {
        this.finish();
      })
    },
    async create(action) {
      // Adjust the website url for upload
      this.website.url = `https://${this.website.url}.${this.website.type === 'Shopify' ? 'myshopify.com/admin/api' : 'b2bwave.com/api'}`

      // Add the corrent image URL to the website
      this.website['image_url'] = this.websiteLogo;

      // Upload the website
      let sync_website = !this.oauth;
      let website_id = await this.uploadWebsite(clone(this.website), sync_website);
      // If website fails to create don't redirect
      if (website_id === undefined) {
        this.finish();
      } else {
        // Behaviour based on action type of create
        switch (action) {
          case 'normal':
            // Assume flat will only
            // be passed in from the first load dashboard
            if (this.flat) {
              this.finish();
              this.$emit('update:selected_tab', 1);
            } else {
              this.$router.push(`/sites/${website_id}`);
            }
            break;
          case 'another':
            if (this.siteLimitMet) {
              this.finish();
            } else {
              this.finish(true);
            }
            break;
          case 'close':
            this.finish();
            break;
          case 'oauth':
            this.finish();
            this.oauth.link_shopify_oauth(website_id);
            break;
        }
      }
    },
    finish(display = false) {
      this.website.name = '';
      this.website.type = '';
      this.website.url = '';
      this.website.api_user = '';
      this.website.api_key = '';
      this.website.settings = {};
      if (display) {
        this.show();
        this.$refs.create_website_dialog.resetAllSteps();
      }
    }
  }
}
</script>