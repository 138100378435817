import { mapActions } from 'vuex';
export const websitesCore = {
  computed: {
    websites() {
      return this.$store.getters.getWebsites;
    },
  },
  created: function() {
    if (!this.$store.getters.getMixinActive('websites')) {
      this.$store.dispatch("getWebsites");
    }
    this.$store.commit('setMixinActive',  [ true, 'websites'] );
  },
  methods: {
    goToWebsite(website_id) {
      this.$router.push(`/sites/${website_id}`);
    },
  }
};

export const websiteCore = {
  watch: {
    '$route.params.id': {
      handler: async function(newId, oldId) {
        // Only refetch if ID has changed
        if (oldId && newId !== oldId) {
          await this.$store.dispatch("getWebsite", newId);
        }
      },
      immediate: true
    }
  },
  computed: {
    website() {
      return this.$store.getters.getWebsite;
    },
    changes() {
      return JSON.stringify(this.$store.state.websites.oldData.website) !== JSON.stringify(this.$store.state.websites.newData.website);
    }
  },
  created: function() {
    this.$store.dispatch("getWebsite", this.$route.params.id);
  },
};

export const websitesLogic = {
  methods: {
    ...mapActions({
      syncWebsite: 'syncWebsite',
      uploadWebsite: 'uploadWebsite',
      updateWebsite: 'updateWebsite',
      updateWebsiteSettings: 'updateWebsiteSettings',
      updateWebsitePriceListMappings: 'updateWebsitePriceListMappings',
      deleteWebsite: 'deleteWebsite',
      discardChanges: 'discardWebsiteChanges',
      addOauthToWebsite: 'addOauthToWebsite',
      convertFromOauthToBasic: 'convertFromOauthToBasic',
    })
  },
};
