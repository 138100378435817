<template>
  <v-container fluid>
    <Shopify v-if="this.$route.path.split('/')[3] === 'confirm'" style="margin-top: 10%"></Shopify>
  </v-container>
</template>

<script>
import Shopify from "@/components/oauth/Shopify.vue";
export default {
  data() {
      return {
      }
  },
  computed: {
  },
  components: {
    Shopify,
  },
  methods: {
  },
}
</script>